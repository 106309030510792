@import '../../../node_modules/athena-next-ui-lib/dist/global-vars';

.section {

  color: #fff;
  margin-top: 8px;
  width: 100%;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    padding-bottom: 4px;
    // margin-bottom: 4px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
  }



  .sectionTitle {
    cursor: pointer;
    color: #fff;
    font-weight: 400;

    &.isEditing {

      color: rgba(255, 255, 255, .5);

      &:hover {
        color: rgba(255, 255, 255, .65);
      }

    }


  }

  .pre {
    font: $fnt-main;
    margin: 0;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .value {
    display: block;

    p {
      margin-top: 0;
    }
  }

  &.expanded {
    display: block;
  }

  &.collapsed {
    display: none;
  }
}


.highly-relevant {
  color: yellow;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
  margin-left: 8px;
  text-transform: uppercase;
  font-size: 12px;
}

//Theming
body:global(.darkTheme) {
  .section {
    .sectionTitle {
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

}

body:global(.lightTheme) {
  .section {
    color: #000;

    .sectionTitle {
      color: rgb(96, 96, 96);

      &:hover {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
}