@import '~athena-next-ui-lib/dist/_global-vars.scss';

// .page {
//   color: #fff;
//   padding: 10px;
//   display: flex;
//   flex-direction: column;
//   width: 95vw;
// }

.tagName {
  color: #fff; //goldenrod;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.tagEtRootLabel {
  color: rgba(255, 255, 255, .8);
  font-size: $lg;
  margin: 0 $md;
}

.tagEtRootValue {
  color: rgba(255, 255, 255, .8);
  font-size: $lg;
  margin: 0;
  font-style: italic;
}


.ruleSummary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 8px 4px 0;
  font: $fnt-main-mono;

  .ruleNum {
    display: flex;
    width: 15px;
    align-items: flex-end;
    justify-content: flex-end;
    color: $title-color;
  }

  .ruleAnd {
    margin: 0 $lg;
    color: $blue;
  }

  .rulePart {
    $c: rgba(0, 0, 0, .75);
    $c2: rgba(255, 255, 255, .25);

    display: flex;
    border: 1px solid $c2;
    border-radius: 2px;
    color: rgba(255, 255, 255, .8);


    font-size: $fnt-sm;

    .ruleKey {
      display: flex;
      align-items: center;
      background: $c;
      padding: 0 $md;
      text-transform: uppercase;
      font-size: $fnt-xxs;
    }

    .ruleValue {
      padding: $xs $md;
      color: rgba(255, 255, 255, 1);
    }


  }


}

.channel {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $md;
  border: 1px solid rgba(255, 255, 255, .2);
  cursor: default;
  background: transparent; //#39738b;
  color: #59b1d5;
  margin: 0 $md;
  border-radius: 2px;

  >b {
    color: rgba(255, 255, 255, .5);
    font-weight: normal;
  }
}

.tagTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.tagType {
  font: $fnt-main-mono;
  margin: $sm $md 0 0;
  border: 1px solid rgba(255, 255, 255, .4);
  padding: 1px $sm;
  color: rgba(255, 255, 255, 1);
  min-width: 50px;
  justify-content: center;
  font-weight: bold;
  border-radius: 2px;

  &.manual {
    background: #2680d0;
  }

  &.auto {
    background: #177c43;
  }


}

.blur {
  filter: blur(3px);
  transition: .25s all ease-in;

  &:hover {
    filter: none;
  }
}

//Theming
body:global(.darkTheme) {

  .tagName,
  .tagEtRootLabel,
  .ruleNum,
  .ruleValue {
    color: rgb(255, 255, 255);
  }
}

body:global(.lightTheme) {
  $bg: rgba(0, 0, 0, .1);
  $border: rgba(0, 0, 0, .25);
  $color: rgba(0, 0, 0, 1);

  .tagName,
  .tagEtRootLabel,
  .ruleNum {
    color: rgb(0, 0, 0);
  }

  .rulePart {
    border-color: $border;

    .ruleKey {
      background: rgba(0, 0, 0, .1);
      color: $color;
    }

    .ruleValue {
      background: rgba(255, 255, 255, 1);
      color: $color;
    }
  }

}