@import '~athena-next-ui-lib/dist/_global-vars.scss';

$alert-custom-bg: #3b7bb0;
$alert-matched-bg: orange;
$timestamp-color: #91fff7;
$manual-tag-bg: #2680d0;


.listContainer {
  user-select: none;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  @extend %custom-scrollbar;
  padding-bottom: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100vw - 55px);
  position: relative;
}

.list {
  position: relative;
  color: #fff;
  display: flex;
  flex-direction: column;


  .row {
    position: relative;
    font: $fnt-main-mono;
    font-size: $fnt-xs;
    display: flex;
    flex: 0 1;
    flex-direction: column;
    flex-wrap: wrap;
    border-width: 1px 1px 1px 4px;
    border-color: rgba(255, 255, 255, .25);
    border-style: solid;
    padding: 2px;
    margin: 10px;

    &:hover {
      background: rgba(255, 255, 255, .1);
      cursor: pointer;
      border-style: dashed dashed dashed solid;
    }


    &.custom {
      border-left-color: $alert-custom-bg;

      &:hover {
        background: transparentize($alert-custom-bg, .9);
      }
    }

    &.accepted {
      border-left-color: $alert-accepted-bg;

      &:hover {
        background: transparentize($alert-accepted-bg, .95);
      }
    }

    &.suggested {
      border-left-color: $alert-suggested-bg;

      &:hover {
        background: transparentize($alert-suggested-bg, .97);
      }
    }

    &.rejected {
      border-left-color: $alert-rejected-bg;

      &:hover {
        background: transparentize($alert-rejected-bg, .9);
      }
    }

    &.matched {
      border-left-color: $alert-matched-bg;

      &:hover {
        background: transparentize($alert-matched-bg, .9);
      }
    }

    &.limitRow {
      display: flex;
      flex-direction: row;
      padding: 8px 0;
      margin-top: 10px;
      margin-bottom: 10px;

      .timeRangeIcon {
        margin: 0 4px 0 12px;
        color: $timestamp-color;
      }



      .zoomText {
        color: rgba(255, 255, 255, 0);
        transition: all .2s ease-in;
      }

      .errorText {
        color: goldenrod;

      }

      &.expanded {
        border-color: rgba(255, 255, 255, .25) transparent;
        border-width: 1px 1px 1px 4px;


        &:hover {
          background: none;
        }
      }

      &:hover {
        border-style: solid;
        border-color: $timestamp-color;

        .zoomText {
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
        }
      }


    }




  }

}

.timestamp {
  color: $timestamp-color;
  margin: 0 4px;

}

.title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
}

.innerRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.innerColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}



.bannerWrapper {
  display: flex;
  align-items: center;
}

.banner {
  margin: 2px 4px;
  padding: 0 8px;
  display: flex;
  position: relative;
  border-radius: 20px;
  text-transform: uppercase;
  font: $fnt-main;
  font-size: $fnt-xs;

  &.custom {
    background: $alert-custom-bg;
    color: #fff;
  }

  &.accepted {
    background: $alert-accepted-bg;
    color: #fff;
  }

  &.suggested {
    background: $alert-suggested-bg;
    color: darken($alert-suggested-bg, 40%);
  }

  &.rejected {
    background: $alert-rejected-bg;
    color: #fff;
  }

  &.matched {
    background: $alert-matched-bg;
    color: #fff;
  }
}

.coreEvents {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  .event {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    margin-top: 2px;
    padding-top: 2px;
    border-top: 1px solid rgba(255, 255, 255, .1);

    .icon {
      flex: 0 1;
      margin-right: 4px;
    }

    .eText {
      display: flex;
      flex: 1 0 95%;
      word-break: break-all;
      //text-indent:-2em;
      //padding-left: 2em;

      //search term match
      b {
        font-weight: normal;
        background: $search-color; //#11BEEA;
        color: #000;
        text-decoration: none;
      }

      //find term match
      u {
        background: $find-color; //#11BEEA;
        color: #000;
        text-decoration: none;
      }
    }

    &:last-child {
      border-bottom: 1px solid rgba(255, 255, 255, .1);
    }
  }




}

.occurrencesCount {
  display: flex;
  margin: 0 8px;
  background: rgba(255, 255, 255, .2);
  padding: 2px 8px;
  color: #fff;
  font-weight: bold;
  //text-transform: uppercase;

}

.findCount {
  display: flex;
  margin: 0 8px;
  background: transparent; //$alert-matched-bg;
  padding: 2px 8px;
  color: $alert-matched-bg;
  font-weight: bold;
  text-transform: uppercase;

}

.commonWords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 0 80%;
  align-items: center;
  margin: 8px;
  font: $fnt-main;
  font-size: 12px;

  span {
    display: flex;
    margin: 0 4px;
    padding: 0 4px;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      // background: rgba(0, 0, 0, 1);
    }
  }
}


.shadow {
  position: sticky;
  top: 0;
  z-index: 1;
  border-top: 1px solid #979797;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, .5);

}




.logs {
  color: $logs-color;
  padding: $md;
}

.hosts {
  color: $hosts-color;
  padding: $md;
}

.serviceGroups {
  color: $service-groups-color;
  padding: $md;
}

.noData {
  font: $fnt-main;
  padding: 20px 10px;
  color: goldenrod;
  font-weight: bold;
  font-size: $fnt-md;

  display: flex;
  flex-direction: column;
}

.alertBody {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;

  .coreEvents {
    display: flex;
    flex: 1 1 100%;
  }

  .wordCloud {
    display: flex;

  }
}

.signalIndicator {
  padding: 0 4px;

}

//Theming
body:global(.darkTheme) {

  .list {
    color: #fff;
  }
}

body:global(.lightTheme) {

  $txt-color: rgb(21, 21, 21);

  .list {
    color: rgb(0, 0, 0);

    .row {
      background: rgb(255, 255, 255);
      transition: .1s background ease-in;

      &:hover {
        background: rgba(0, 0, 0, .05);
      }

      &.limitRow {

        .timeRangeIcon {
          color: $txt-color;
        }


        .errorText {
          color: goldenrod;

        }

        &.expanded {
          border-color: rgba(255, 255, 255, .25) transparent;
          border-width: 1px 1px 1px 4px;


          &:hover {
            background: none;
          }
        }

        &:hover {
          border-style: solid;
          border-color: $txt-color;


        }


      }
    }
  }

  .serviceGroups {
    color: rgb(107, 166, 139);
    filter: brightness(80%);
  }

  .hosts {
    color: rgb(173, 157, 76);
  }

  .logs {
    color: rgb(63, 124, 133);
  }

  .timestamp {
    color: $txt-color;
  }

  .title {
    color: $txt-color;
  }

  .shadow {
    border-top: 1px solid rgb(153, 153, 153);
    box-shadow: 0 0 5px 5px rgb(204, 204, 204);
  }


}

//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {
  .occurrencesCount {
    background: rgba(141, 190, 218, 1);
  }
}