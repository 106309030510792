@import '~athena-next-ui-lib/dist/_global-vars.scss';


$yellow: #e8c819;
$black: #000;
$duration: .15s;
$pad: $md $sm;


.zoomBar {
  font: $fnt-main;
  font-size: $fnt-sm;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid #888;
  color: #fff;

  flex: 0 1;
  padding: 0 $md;

  transition: $list-mode-transition;

  &.read {
    background: $list-mode-read-bg;
    color: $list-mode-read-txt;
  }

  &.edit {
    background: rgba(255, 255, 255, .2); //$list-mode-edit-bg;
    color: $list-mode-edit-txt;
  }

  &.peek {
    background: $list-mode-peek-bg;
    color: $list-mode-peek-txt;
  }


  button {
    flex-wrap: wrap !important;
    white-space: normal;
  }


  padding-right: $lg;

}

.peekModeDesc {
  span {
    padding: $pad;
  }

  b {
    padding: $pad;
    text-transform: uppercase;
    color: #3c78b1;
  }
}


.zoomTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  font-size: $fnt-md;
  font-weight: bold;

  padding: $sm 0;
  margin: $sm;

  >.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .help {
    font-size: $fnt-xs;
    font-weight: normal;
    font-style: italic;
  }

}

.forceRight {
  margin-left: auto;
}

.keyCount {
  display: flex;
  flex-direction: column;
  margin: $lg;
  border-right: 1px solid rgba(255, 255, 255, .5);
  padding-right: $sm;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    //background: #000;
    padding: 0 4px;
    border-radius: 3px;
    font-weight: bold;
    margin: 0 $sm;

  }

  &.invalid {
    color: #f3e15e;
  }
}

.tip {
  display: flex;
  flex-direction: column;
  margin-right: $md;
  word-break: break-word;

  >div {
    margin: 0 0 $md;
  }

}

.rawEventText {
  background: rgba(0, 0, 0, .2);
  @extend %custom-scrollbar;

  .rawEventTextRow {

    font: $fnt-main-mono;
    padding: $md;
    line-height: 20px;

    border-top: 1px solid rgba(255, 255, 255, .25);
  }
}

//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {

  .zoomBar {
    &.read {
      background: rgba(192, 192, 192, 1);
    }
  }

}