@import '~athena-next-ui-lib/dist/_global-vars.scss';


.loading {
  margin: $xl;
}

.error {
  padding: 0 20px;
  color: $lite-8;

  h3 {
    margin: $md 0 0;
    color: goldenrod;
  }

  p {
    margin: $md 0;
  }
}

.noData {
  font: $fnt-main;
  padding: 20px 10px;
  color: goldenrod;
  font-weight: bold;
  font-size: $fnt-md;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {

  .error {
    color: rgba(0, 0, 0, .8);
  }
}