@import '~athena-next-ui-lib/dist/_global-vars.scss';

.Bookmarks {
  $golden: #eeae0f;
  position: relative;
  margin-right: 4px;



  .BookmarksField {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border: 1px solid rgba(255, 255, 255, .65); //$field-border-color;
    background: $field-bg-color;
    color: $golden; //$field-text-color;
    padding: $md - 2 $md;
    border-radius: 30px;
    font-size: $fnt-md;
  }

  .BookmarksSelectedValues {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .BookmarksMenu {
    position: absolute;
    z-index: 10;
    display: none;
    left: 0;
    top: 100%;
    max-width: 30vw;
    background: $scilo-menu-bg;
    border: 1px solid $scilo-menu-border;
    color: rgba(255, 255, 255, .7);

    h3 {
      margin: 0;
      padding: $sm $md;
      color: $golden; //#fff;
    }

    .BookmarksMenuItem,
    .BookmarksMenuItemSelected {
      display: flex;
      justify-content: flex-start;
      padding: $sm $md;
      font-size: $fnt-md + 1;
      white-space: nowrap;
      cursor: pointer;

      .itemControls {
        margin-left: auto;
        padding-left: $md;
        color: #fd6f88;
        visibility: hidden;
      }

      &:hover {
        color: $scilo-menu-txt;
        background: $scilo-menu-hover;

        .itemControls {
          visibility: visible;
        }
      }




    }

    .BookmarksMenuItemSelected {
      color: $blue;
      font-weight: bold;
    }


  }


  hr {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, .25);
  }

  &:hover {

    .BookmarksField {
      background: rgba(0, 0, 0, .5);
      //border-color: #fff;
      color: $golden; //#20466B;
    }

    .BookmarksMenu {
      display: flex;
      flex-direction: column;
      justify-items: flex-start;

      .list {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        max-height: 50vh;
        @extend %custom-scrollbar;
      }
    }
  }
}

//Theming
body:global(.darkTheme) {
  $bookmark-hover: rgb(37, 37, 37);
  $bookmark-title-color: #eeae0f;
  $bookmark-bg: rgba(0, 0, 0, 1);
  $bookmark-item-text: rgba(255, 255, 255, 1);


  .BookmarksMenu {
    background: $bookmark-bg;
    color: $bookmark-item-text;

    h3 {
      color: $bookmark-title-color;
    }

    .BookmarksMenuItem,
    .BookmarksMenuItemSelected {
      &:hover {
        background: $bookmark-hover;
        color: $bookmark-item-text;
      }
    }
  }

}


body:global(.lightTheme) {
  $bookmark-hover: rgb(245, 245, 245);
  $bookmark-title-color: rgba(0, 0, 0, .5);
  $bookmark-bg: rgb(255, 255, 255);
  $bookmark-item-text: rgb(33, 33, 33);

  .BookmarksMenu {
    background: $bookmark-bg;
    color: $bookmark-title-color;

    h3 {
      color: inherit;
    }

    .BookmarksMenuItem,
    .BookmarksMenuItemSelected {
      &:hover {
        background: $bookmark-hover;
        color: $bookmark-item-text;
      }
    }
  }

}