@import '~athena-next-ui-lib/dist/_global-vars.scss';

$hover-color: rgba(255, 255, 255, .05); //#515e69;
$row-color: #46494D; //rgba(167, 167, 167, 0.2);

$yellow: #e8c819;
$black: #000;
$duration: .15s;

.eventList {
  padding: 0;

  width: inherit;
  height: 100%;
  overflow-y: auto;

  @extend %custom-scrollbar;

  &.notGroupedByDay {
    padding-left: $lg;
  }

  &.wrap {
    overflow-x: hidden;
  }

  &.nowrap {
    overflow-x: auto;
  }
}

.eventRow {
  transform: scale(1);
  position: relative;
  display: block;
  font: $fnt-main-mono;
  color: #f0f0f0; //$lite-9;
  word-break: break-word;
  background: transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  padding: 0 0 0 $md;
  margin: 0;

  .wrap {
    width: 100%;
  }

  .nowrap & {
    min-width: 4000px;
  }

  &:hover {
    background: $hover-color;
    border-color: $blue-dk; //lighten($hover-color, 20%);
    cursor: pointer;

    .peekToggle {
      display: flex;
      //transform: translateX(0px);
    }

    .metadataToggle {
      visibility: visible;
    }

    .eventMenuIcon {
      visibility: visible;
    }
  }

  &.alertKeyEvent {
    &:hover {
      background: none;
      border-color: transparent;
    }
  }

  &.selected {
    border: 1px dashed rgba(255, 255, 255, .8);
  }


}

.event {
  transform: scale(1);
  position: relative;
  font: $fnt-main-mono;
  color: $lite-9;
  padding: $lg 0 $lg;
  line-height: 18px;

  .wrap & {
    display: flex;
    align-items: flex-start;
    word-break: break-all;
    width: 100%;
    padding: $sm 0;

    background: transparent;
  }

  .nowrap & {
    display: flex;
    padding: 0;

    >div {
      padding: $sm;
    }
  }



  $columnLeftWidth: 20px;
  $columnRightWidth: 40px;

  .columnLeft {
    //position:sticky;
    width: $columnLeftWidth;
    left: 0;
    top: 2px;
    z-index: 100;
    //background: linear-gradient( -90deg, transparent 0%, #262B30 10%, #262B30 100%);

  }

  .columnRight {
    position: sticky;
    width: $columnRightWidth;
    right: 0;
    top: 4px;
    z-index: 100;
    background: transparent;// linear-gradient(90deg, transparent 0%, #262B30 10%, #262B30 100%);


  }

  .columnMid {
    position: relative;
    margin-left: $columnLeftWidth;
    margin-right: $columnRightWidth;
    z-index: 0;
  }


  span {
    margin-right: $sm;
    word-break: break-all;
  }

  b {
    font-weight: normal;
    background: $search-color; //#11BEEA;
    color: #000;
    text-decoration: none;
  }

  u {
    background: $find-color; //#11BEEA;
    color: #000;
    text-decoration: none;
  }


  .peekToggle2 {
    position: relative;
    visibility: hidden;
    color: $yellow;
    padding: 0 1px 0 5px;
    border-radius: 24px;
    margin: 0 $sm;

    &:hover {
      background: $yellow;
      color: $black;
    }

  }

  .peekToggle {


    position: absolute;
    right: 5px;
    top: 2px;


    display: none; //flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    overflow: hidden;

    z-index: 2;
    background: $yellow;
    border: 2px solid $yellow;
    color: $black;
    padding: 0 1px;
    border-radius: 24px;

    //transform: translateX(100px);
    transition-duration: $duration;
    transition-timing-function: ease-in;
    transition-property: transform, background;
    transition-delay: $duration, 0s;

    .peekIcon {
      display: inline-block;
      font-size: 13px;

    }

    .peekLabel {
      font: $fnt-main;
      text-transform: uppercase;
      white-space: nowrap;
      width: 0;
      min-width: 0;
      transition: $duration all ease-in;
      opacity: 0;
      padding: 0;

    }

    &:hover {
      background: $black;
      border: 1px solid $yellow;
      color: $yellow;

      .peekIcon {
        transform: translateX(-5px) translateY(-1px);
      }

      .peekLabel {

        min-width: 40px;
        width: auto;
        opacity: 1;
        padding: 0 4px 0 8px;
      }
    }
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $sm $sm;
    width: 100%;
  }

  &:hover {

    .events {
      color: $lite-10;
      background: $lite-2;
    }

    .peekToggle {
      display: flex;
      //transform: translateX(0px);
    }

    .peekToggle2 {
      //display: flex;
      visibility: visible;
      cursor: pointer;
      //transform: translateX(0px);
    }

    .metadataToggle {
      visibility: visible;
    }
  }


  &.inline {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;

    >* {
      margin-right: $md;
    }
  }


  $rare0: transparent;
  $rare1: #f5ac8e;
  $rare2: #ffcdcd;


  .rare-label-0 {
    background-color: $rare0
  }

  .rare-label-1,
  .rare-label-2 {
    font-size: $fnt-xs;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, .1);
    border: 1px solid rgba(255, 255, 255, .4);
    padding: 0 6px;
    border-radius: $md;
  }

  .rare-label-1 {
    color: $rare1
  }

  .rare-label-2 {
    color: $rare2
  }

  .timestamp {
    color: $title-color;
    font-weight: 700;
    padding: $xxs 0;
    border-radius: $xs;
    padding-right: $md;
  }

  .severity-lvl-1 {
    color: $sev-color-1;
    padding-right: $md;
  }

  .severity-lvl-2 {
    color: $sev-color-2;
    padding-right: $md;

  }

  .severity-lvl-3 {
    color: $sev-color-3;
    padding-right: $md;
  }

  .severity-lvl-4 {
    color: saturate($sev-color-4, 20%);
    padding-right: $md;
  }

}

.rowGroup {

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  background: linear-gradient(90deg, transparent 0, transparent 19px, rgba(255, 255, 255, .1) 19px, rgba(255, 255, 255, 0) 32px);

  &.noBg {
    background: none;
    padding-left: $md;
  }

  &:last-child {
    padding-bottom: 60px;
  }

  .dateTime {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    top: $md;
    z-index: 1;
    color: $title-color;
    padding: 0 $sm 0 $sm;
    font: $fnt-main-mono;
    font-size: $fnt-xs;
    border-radius: $sm;
    margin: 2px 0 0 0;

    text-orientation: sideways;
    writing-mode: vertical-lr;
    text-transform: uppercase;
    transform: scale(-1, -1);

    .month {
      display: flex;
      justify-content: center;
      border-radius: $sm $sm 0 0;
      font-weight: 700;
      background: $row-color;
      font-size: $fnt-sm;
      text-transform: uppercase;

      padding: 8px 0 0 0;

    }

    .day {
      display: flex;
      justify-content: center;
      border-radius: 0;
      border: 2px solid $row-color;

      font-size: $fnt-md;
      font-weight: 700;
      background: black;

      padding: 0 0 8px 0;

    }

    .date {
      position: relative;
      color: $lite-5; //$title-color;
      font-weight: 700;
      font-size: $fnt-xs;
      padding: $sm 0 $md;
      border-radius: 0 0 4px 0;

      background: rgba(255, 255, 255, .1);


      &:after {
        $size: 14px;
        $bg: rgba(255, 255, 255, .1);
        content: "";

        position: absolute;
        top: -$size;
        width: 0;
        height: 0;

        border-style: solid;
        border-width: $size 0 0 $size;
        border-color: transparent transparent transparent $bg;
      }

    }

  }



  &:hover {
    .date {
      color: $title-color;
    }
  }


}

.allTags {
  $tag-color: $blue-dk;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: $md 0;
  @extend %custom-scrollbar;

  .tag {
    display: flex;
    border-radius: $xs;
    margin: $sm;
    border: 1px solid $tag-color;
    color: #fff;

    .tagKey,
    .tagValue {
      padding: $xs $md;
      font-size: $fnt-xs;
      background: $tag-color;
      border-radius: $xs 0 0 $xs;
    }

    .tagValue {
      background: transparent;
      border-radius: 0 $xs $xs 0;
    }
  }


}

.metadataToggle {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;

  >div {
    position: absolute;
    bottom: -14px;
    background: #000;
    border: 2px solid $blue-dk;
    color: #fff;
    border-radius: 24px;
    z-index: 2;
    font-size: 10px;
    padding: $sm $sm + 1;

    &:hover {
      transform: scale(1.5);
    }
  }
}


.scrollContainer {
  //position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  @extend %custom-scrollbar;

  // padding-bottom: 50vh;
  //overflow-y: auto;
  //overflow-x: hidden;
}

.eventColumn {
  flex: 1 0;
  //text-indent:-2em;
  //padding-left: 2em;

  .wrap & {
    white-space: normal;
  }

  .nowrap & {
    white-space: nowrap;

  }


}

.annotationColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
  //padding:0 40px 0 $md;
  padding: 0;

  .wrap & {
    flex: 0 1 160px;
  }

  .nowrap & {
    flex: 0 1 120px;
  }


  font-size:18px;

  .relatedIncidentsUnknown {
    cursor: pointer;
    color: transparent; //rgba(255,255,255,.25);
    margin: 0 $md;
    visibility: hidden;
  }

  .hasNoAnnotation {
    cursor: pointer;
    color: transparent; //rgba(255,255,255,.25);
    margin: 0 $md;
    visibility: hidden;
  }

  .hasNoRelatedIncidents {
    cursor: pointer;
    color: rgba(255, 255, 255, .25);
    margin: 0 $md;
    visibility: visible;
  }

  .hasAnnotation,
  .hasRelatedIncidents {
    cursor: pointer;
    color: $blue;
    position: relative;
    margin: 0 $sm;
    visibility: visible;



    &.showCount {
      &:after {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        content: attr(data-count);
        top: -8px;
        right: -8px;
        padding: 1px 4px;
        border-radius: 24px;

        background: #b11a1a; //darken($blue, 40%);
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        font-family: 'Roboto Mono', monospace;
        border: 1px solid black;


      }
    }

    &:hover {
      color: $title-color;
    }
  }

  :hover>& {

    .hasNoAnnotation,
    .relatedIncidentsUnknown {
      color: $title-color;
      //visibility: visible;
    }
  }
}

.titleCount {
  padding: 1px 12px;
  border-radius: 5px;
  background: #b11a1a; //darken($blue, 40%);
  color: #fff;
  font: $fnt-main-mono;
  font-size: $fnt-lg;
  font-weight: bold;
  margin-right: $sm;

  &.disabled {
    background: rgba(255, 255, 255, .2);
  }


}

.relatedIncidents {
  color: $blue;
  padding-right: 10px;
}

.etext {
  font: $fnt-main-mono;
  font-size: $fnt-sm;
  padding: 12px 8px;
  background: rgba(0, 0, 0, .4);
}

.svc_grp_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}

.svc_grp_list_container {
  margin: 10px;
}

.annotationList {
  max-height: 60vh;
  @extend %custom-scrollbar;

  .annotationListItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: #fff;
    padding: $lg $md;
    transition: .25s margin-left ease-in;

    .message {
      display: inline;
      white-space: pre-wrap;
      word-break: break-all;
      font: $fnt-main;
    }

    .timestamp {
      color: $title-color;
    }

    .username {
      color: $hosts-color;
    }

    .delete {
      margin-left: auto;
      padding: 0 0 0 $lg;
      visibility: hidden;

      >div {
        display: inline-block;
        color: #ff5a5a;
        padding: 3px 5px 1px;
        border-radius: 45px;
      }
    }

    &.markedForDelete {
      $stripe : rgb(255, 152, 152, .25);
      $stripe-size: 20px;
      background-image: repeating-linear-gradient(-45deg, transparent, transparent $stripe-size, $stripe $stripe-size, $stripe $stripe-size * 2);
      background-size: 200% 100%;

      animation: loading-slide 5s linear infinite;

      @keyframes loading-slide {
        from {
          background-position: left;
        }

        to {
          background-position: right;
        }
      }

      .delete {
        visibility: hidden !important;
      }
    }

    &:hover {
      background: rgba(255, 255, 255, .05);

      .delete {
        visibility: visible;

        >div {
          &:hover {
            background: rgba(255, 255, 255, .25);
            color: #ff9898;
            cursor: pointer;
          }
        }
      }
    }

    +.annotationListItem {
      border-top: 1px solid rgba(255, 255, 255, .25);
    }
  }


}



.annotationListChatStyle {
  max-height: 60vh;
  @extend %custom-scrollbar;

  .annotationListItem {
    display: flex;
    flex-direction: column;


    color: #fff;
    padding: $md $lg 0 $md;
    transition: .25s margin-left ease-in;

    $their-msg: #2f77ab; //#576eb6; //rgba(49,132,99,1);//(rgba(140,172,104,1); //#86BB71;
    $my-msg: #5789b6; //rgb(67, 109, 145); //#94C2ED;
    $arw-size: 16px;

    .messageWrapper {
      position: relative;
      margin: 0;
      padding: 0;

      .message {
        display: inline-block;
        width: 100%;
        white-space: pre-wrap;
        word-break: break-all;
        font: $fnt-main;
        background: $their-msg;
        color: #fff;
        padding: $sm $md $sm $md;
        border-radius: $sm;
        margin: 0;
        border: 2px dotted transparent;
      }

      .deleteMsg {
        position: absolute;
        z-index: 1;
        top: -5px;
        right: -5px;
        visibility: hidden;

        >div {
          display: flex;
          color: #ff5a5a;
          padding: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background: #fff;
        }
      }
    }


    .attribution {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: $sm;
      margin-left: $lg;
      margin-right: $lg;
      margin-bottom: $lg;

      .timestamp {
        color: rgba(255, 255, 255, .5); //$title-color;
      }

      .username {
        color: $hosts-color;
        padding: 0 $md;
      }

      .arrowDown {
        position: relative;

        width: 0;
        height: 0;
        top: -6px;

        border-top: $arw-size solid $their-msg;
        border-left: $arw-size solid transparent;



      }
    }

    &.mine {
      .message {
        background: $my-msg;
      }

      .attribution {
        flex-direction: row-reverse;

        .arrowDown {
          border-left: none;
          border-top: $arw-size solid $my-msg;
          border-right: $arw-size solid transparent;

        }
      }


    }




    &.markedForDelete {
      $stripe : rgb(255, 152, 152, .25);
      $stripe-size: 20px;
      background-image: repeating-linear-gradient(-45deg, transparent, transparent $stripe-size, $stripe $stripe-size, $stripe $stripe-size * 2);
      background-size: 200% 100%;

      animation: loading-slide 5s linear infinite;

      @keyframes loading-slide {
        from {
          background-position: left;
        }

        to {
          background-position: right;
        }
      }

      .delete {
        visibility: hidden !important;
      }
    }

    &:hover {

      //background: rgba(255,255,255,.05);
      .message {
        border: 2px dotted rgba(255, 255, 255, .75);
      }

      .deleteMsg {
        visibility: visible;

        >div {
          &:hover {
            background: red;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }


  }


}

.eventTypeIcon {
  border-radius: 24px;
  padding: 0 $xs;
  border: 1px solid transparent;


}

.selectableKey {
  filter: grayscale(1);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: inline-block;
  opacity: .5;

  &:hover {
    filter: none;
    opacity: 1;
    border: 1px solid #fff;
    cursor: pointer;

    animation: glow 2s ease-in-out infinite;

    background: rgba(255, 255, 255, .25);

    >svg {
      transform: scale(.8);
    }
  }
}

@keyframes glow {
  $s: 4px;

  0% {
    box-shadow: 0 0 $s $s rgba(255, 255, 255, 0);
  }

  50% {
    box-shadow: 0 0 $s $s rgb(185, 207, 85);
  }

  0% {
    box-shadow: 0 0 $s $s rgba(255, 255, 255, 0);
  }
}




.eventMenuShield {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-menu;

  .eventMenu,
  .eventSubMenu {
    position: absolute;
    overflow-y: visible;
    @extend %menu;

    .eventMenuItem,
    .eventMenuItemDivider {
      @extend %menu-item;
      position: relative;

      padding: 6px 8px;


      .eventMenuIcon {
        width: 20px;
      }

      .eventSubMenuIcon {
        margin-left: auto;

      }

      .eventMenuLoading {
        margin-left: auto;
      }

      .badge {
        margin-left: auto;
        background: #cc0000;
        color: white;
        border-radius: 50%;
        padding: 1px 4px;
        font-size: $fnt-xs;
        font-weight: bold;
        text-align: center;

      }

    }

    .eventMenuItemDivider {
      border-top: 1px solid rgba(255, 255, 255, .4);
      margin: 0;
      padding: 0;

      &:hover {
        background: none !important;
      }
    }

  }
}



.eventSubMenu {
  position: absolute;
  right: -10px;
  top: 15px;
  z-index: 1;
}

.eventMenuItem {

  >.eventSubMenu {
    display: none;
  }

  &:hover {
    >.eventSubMenu {
      display: block;
    }
  }

}



.eventMenuIconWrapper {
  .eventMenuIcon {
    margin-left: 8px;
    visibility: hidden;
  }
}


//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {

  .eventList {
    background: #fff;
    @extend %custom-scrollbar-light;

    .rowGroup {
      .dateTime {
        .date {
          $bg: rgba(0, 0, 0, .1);
          color: #000;
          background: rgba(0, 0, 0, .1);
          font-weight: normal;

          &:after {

            border-color: transparent transparent transparent $bg;
          }

        }
      }
    }
  }

  .eventRow {
    color: #000;
    border-bottom-color: rgba(0, 0, 0, .2);

    &.selected {
      border: 1px dashed rgba(0, 0, 0, .8);
    }



    .severity-lvl-1,
    .severity-lvl-2,
    .severity-lvl-3,
    .severity-lvl-4,
    .rare-label-0,
    .rare-label-1,
    .rare-label-2,
    .timestamp {
      filter: brightness(70%);
    }



  }

  .event {
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
  }

  .metadataToggle {


    >div {
      background: rgb(21, 101, 192);
      border: 2px solid rgb(21, 101, 192);

      &:hover {
        transform: scale(1.5);
      }
    }
  }


  .allTags {
    $tag-color: $blue-dk;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: $md 0;
    @extend %custom-scrollbar-light;

    .tag {
      .tagValue {
        background: transparent;
        color: #000;
      }
    }


  }

  .eventMenuShield {

    .eventMenu,
    .eventSubMenu {
      color: #000;
      background: #fff;
      border: 1px solid rgb(204, 204, 204);
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, .2);

      .eventMenuItem,
      .eventMenuItemDivider {
        &.endGroup {
          border-bottom: 1px solid rgb(204, 204, 204);
        }

        &.selected {
          color: #000;
        }

        &:hover {
          background: rgba(0, 0, 0, .1);
          color: #000;
        }

      }

      .eventMenuItemDivider {
        border-top: 1px solid rgba(0, 0, 0, .4);

        &:hover {
          background: none !important;
        }
      }

    }
  }

}