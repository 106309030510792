@import '~athena-next-ui-lib/dist/_global-vars.scss';

$alert-custom-bg: #3b7bb0;
$alert-matched-bg: #ff9632;
$timestamp-color: #91fff7;

$region: transparent;

.region {
  fill: $region
}

.timeline {
  position: relative;
  display: flex;
  flex: 0 1;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  user-select: none;
}

.graph {


  .kebab {
    $c: #999;
    $c1: #ccc;

    .spike {
      stroke: transparent;
      stroke-width: 0;
      fill: $c;
      fill-rule: evenodd;
    }



    .mark {
      stroke: $c;
      stroke-width: 1.5px;
      fill: rgba(0, 0, 0, 1);
      fill-rule: evenodd;
    }






    .kebabBucket {
      fill: transparent;

      .bucketCount {
        transition: .25s all ease-in;
        fill: $timestamp-color;
        font: $fnt-main-mono;
        font-size: $fnt-xs;
        text-anchor: middle;
      }

      &.expanded {
        .spike {
          fill: $c1
        }

        .mark {
          stroke: $c1
        }
      }



      &.matched {
        .mark {
          fill: $alert-matched-bg;
        }
      }

      &:hover {
        .bucketCount {
          transform: translate(0, 25px)
        }
      }
    }

  }



}

.histogram {

  .bucket {
    fill: rgba(255, 255, 255, .05);

    &.selected {
      fill: rgba(70, 130, 180, 0.2);
    }
  }

  .logsLine {
    stroke: rgba(255, 255, 255, .5);
    fill: transparent;
    stroke-dasharray: 5 10;
    background: rgba(255, 255, 255, .5);
  }

  .rareLine {
    stroke: rgb(224, 125, 125);
    fill: transparent;
    background: rgb(224, 125, 125);
  }

  .timeRangeBox {
    fill: #000;
  }

  .timeRangeLabel {
    fill: $timestamp-color;
    text-anchor: middle;
    font: $fnt-main-mono;
    font-size: $fnt-xs;
  }

  .histogramTooltipRect {
    fill: transparent;
    stroke-width: 0;

    &:hover {
      fill: rgba(255, 255, 255, .1);
      stroke: rgba(255, 255, 255, .5);

    }
  }




}

.xAxis {

  .xAxisLine {
    stroke: rgba(255, 255, 255, .5);
    stroke-width: 1.5px;
    fill: transparent;
  }

  .xAxisLabel {
    fill: $timestamp-color;
    font: $fnt-main-mono;
    font-size: $fnt-sm;

    &.end {
      text-anchor: start;
    }

    &.start {
      text-anchor: end;
    }

    &.minor {
      font-size: $fnt-xs;
    }
  }
}

.buckets {


  .bucket {
    fill: transparent; //rgba(255, 255, 255, .05);

    &.selected {
      fill: rgba(70, 130, 180, 0.2);
    }
  }

  .histogramBucket {
    fill: rgba(255, 255, 255, .05);
  }

  .dragHandle {
    fill: rgba(204, 204, 204, 1);
    stroke: rgba(255, 255, 255, .5);
  }

}

.tooltipContainer {
  position: absolute;
  top: -10000px;
  left: -10000px;
  z-index: 1000;
  max-width: 30vw;

  .tooltip {
    padding: 4px;
    position: relative;
    border: 1px solid rgba(255, 255, 255, .3);
    background: #2c2c2c;
    color: rgba(255, 255, 255, .8);
  }

  .tooltipRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .tooltipTitle {
    margin: 0 4px;
    font-weight: bold;
    font-size: 14px;
  }
}



.symbol {

  fill: $alert-accepted-bg;

  &.custom {
    $alert-custom-txt: white;
    fill: $alert-custom-bg;
    //stroke: $alert-custom-txt;
  }

  &.accepted {
    fill: $alert-accepted-bg;
    //stroke: $alert-accepted-txt;
  }

  &.suggested {
    fill: $alert-suggested-bg;
    //stroke: $alert-suggested-bg;
  }

  &.rejected {
    fill: lighten($alert-rejected-bg, 20%);
    //stroke: lighten($alert-rejected-bg, 20%);
  }

  &.matched {

    fill: $alert-matched-bg;
    //stroke: $match-color;
  }

  &:hover {
    stroke: #fff;
    stroke-width: 2px;
  }
}

.histogramRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: $fnt-sm;
  color: rgba(255, 255, 255, 1);


  .logsLine {
    background: rgba(255, 255, 255, .5);
  }

  .rareLine {
    background: rgb(224, 125, 125);
  }

  >div {
    margin: 2px 4px;
  }

  .swatch {
    width: 12px;
    height: 12px;
  }

  .label {
    width: 80px;
  }

  .num {
    margin-left: auto;
    font-weight: bold;
  }
}

.noData {
  font: $fnt-main;
  padding: 20px 24px;
  color: goldenrod;
  font-size: $fnt-md;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.openTimeRangePicker {
  cursor: pointer;
}

.wordCloud {
  background-color: rgba(0, 0, 0, .6);
}

body:global(.darkTheme) {}

body:global(.lightTheme) {

  $timestamp-color: rgb(0, 0, 0);



  .xAxis {

    .xAxisLine {
      stroke: rgb(204, 204, 204);

    }

    .xAxisLabel {
      fill: rgb(0, 0, 0);
    }
  }

  .histogramRow {

    color: rgba(0, 0, 0, 1);


    .logsLine {
      background: rgba(0, 0, 0, .5);
    }

    .rareLine {
      background: rgb(189, 85, 85);
    }

  }

  .tooltipContainer {


    .tooltip {

      border: 1px solid rgba(0, 0, 0, .3);
      background: rgb(214, 214, 214);
      color: rgba(0, 0, 0, .8);
    }
  }

  .timeRangeBox {
    fill: rgb(255, 255, 255);
  }

  .timeRangeLabel {
    fill: $timestamp-color;
  }


  .graph {


    .kebab {
      $c: rgb(192, 192, 192);
      $c1: rgb(102, 102, 102);

      .spike {
        fill: $c;
      }

      .mark {
        stroke: $c;
        fill: rgb(250, 250, 250);
      }

      .kebabBucket {
        fill: transparent;

        .bucketCount {
          fill: $timestamp-color;
        }

        &.expanded {
          .spike {
            fill: $c1
          }

          .mark {
            stroke: $c1
          }
        }
      }

    }



  }

  .wordCloud {
    background-color: rgba(0, 0, 0, .05);
  }


}