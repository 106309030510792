@import '../../../node_modules/athena-next-ui-lib/dist/global-vars';


.eventGraph {
  position: relative;
  display: flex;
  flex-direction: column;
  //text-transform:uppercase;
  $labelWidth: 100px;

  .eventGraphMajorLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    padding: 0 $sm;
    color: rgba(255, 255, 255, .8);
    font-family: $fnt-main-mono;
    font-size: $fnt-xs;
    background: rgba(255, 255, 255, 0.1);
    z-index: 2;
  }

  .eventGraphMinorLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    text-align: right;
    font-family: $fnt-main-mono;
    font-size: $fnt-xxs;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
    z-index: 1;
  }

  .eventTimeline {
    .eventTimelineAxis {
      stroke: rgba(255, 255, 255, 0.1);
      stroke-width: 2px;
      stroke-linecap: round;
      fill: transparent;
    }

    .eventTimelineNode {
      transition: all 0.15s ease-in-out;
      cursor: pointer;
      pointer-events: all;

      &.severity-lvl-1 {
        fill: $sev-color-1;
        color: $sev-color-1;
      }

      &.severity-lvl-2 {
        fill: $sev-color-2;
        color: $sev-color-2;
      }

      &.severity-lvl-3 {
        fill: $sev-color-3;
        color: $sev-color-3;
      }

      &.severity-lvl-4 {
        fill: $sev-color-4;
        color: $sev-color-4;
      }

      $key-color: gold;

      &.key {
        stroke: $key-color; //$first-color;
        fill: rgba(0, 0, 0, .5); // lighten($first-color, 40%);
        stroke-width: 2px;
      }

      &.hallmark {
        stroke: rgb(38, 172, 222); //$key-color; //$worst-color;
        fill: rgba(0, 0, 0, .5); //lighten($worst-color, 40%);
        stroke-width: 2px;
      }


    }
  }

  .eventTimelineAxisY {
    stroke: goldenrod;
    stroke-width: 1px;
    stroke-dasharray: 2;
  }

  .eventTimelineAxisYText {
    fill: rgba(255, 255, 255, 1);
    font-family: $fnt-main-mono;
    text-anchor: start;
    font-size: $fnt-xxs;

    &.end {
      text-anchor: end;
    }
  }

  .eventGraphRows {
    display: flex;
    flex-direction: column;

    .eventGraphRow {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:nth-child(odd) {
        background: rgba(255, 255, 255, 0.025);
      }
    }
  }
}

.eventTimelineNode.selected {
  fill: transparent !important;
  stroke-width: 1.25px;
  stroke: #fff;
}

.scrollable {
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;

  @extend %custom-scrollbar;
}

.tooltip {
  $bg: $tooltip-bg;
  position: absolute;
  z-index: $z-index-tooltip;
  background: $bg;
  border: 1px solid $tooltip-border;

  color: rgb(255, 255, 255);
  border-radius: $sm;

  word-break: break-all;
  padding: $sm;
  max-height: 20vh;
  max-width: 60vw;
  overflow: auto;
  font: $fnt-main-mono;



  >* {
    margin-right: $md;
  }

  .severity-lvl-1 {
    fill: $sev-color-1;
    color: $sev-color-1;
    margin-right: $sm;
  }

  .severity-lvl-2 {
    fill: $sev-color-2;
    color: $sev-color-2;
    margin-right: $sm;
  }

  .severity-lvl-3 {
    fill: $sev-color-3;
    color: $sev-color-3;
    margin-right: $sm;
  }

  .severity-lvl-4 {
    fill: $sev-color-4;
    color: saturate($sev-color-4, 20%);
    margin-right: $sm;
  }


  .logs {
    color: $logs-color;
    margin-right: $sm;
  }

  .hosts {
    color: $hosts-color;
    margin-right: $sm;
  }

  .timestamp {
    color: $title-color;
    font-weight: 700;
    //font-size:$fnt-md;
    //background: $dark-8;
    padding: $xs $sm;
    border-radius: $xs;
    //white-space: nowrap;
    margin-right: $sm;
  }

  .eventWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }


  .noIcon {
    visibility: hidden;
    margin-right: 7px;
  }

  .firstIcon {
    color: $first-color;
    font-size: 15px;
    margin-right: 4px;

  }

  .worstIcon {
    color: $worst-color;
    font-size: 15px;
    margin-right: 4px;
  }

  .firstWorstIcon {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    white-space: nowrap;

    >*+* {
      margin-top: -9px;
    }
  }

}

.histogram {
  position: sticky;
  top: 0;
  background: #53575B;
  z-index: 2;

  .histogramLabelStart,
  .histogramLabelEnd {

    fill: $title-color;
    font-size: $fnt-xs;
  }

  .histogramLabelEnd {
    text-anchor: end;
  }

  .histogramAxis {
    stroke: rgba(255, 255, 255, .25);
    stroke-width: 1px;
  }

  .histogramBar {
    fill: rgba(255, 255, 255, .5);

    &:hover {
      fill: rgba(255, 255, 255, .8);
    }

  }


}

.serviceGroups {
  text-align: right;
  //max-width: 70%;
  margin-left: auto;

  span {
    color: $service-groups-color;
  }

}

//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {
  .section {
    color: #000;

    .sectionTitle {
      color: rgb(96, 96, 96);

      &:hover {
        color: rgba(0, 0, 0, 1);
      }
    }
  }


  .eventGraph {


    .eventGraphMajorLabel {

      color: rgba(0, 0, 0, .8);
      background: rgba(0, 0, 0, 0.1);
    }

    .eventGraphMinorLabel {
      color: rgba(0, 0, 0, 1);
    }

    .eventTimeline {
      .eventTimelineAxis {
        stroke: rgba(0, 0, 0, 0.1);
      }

      .eventTimelineNode {


        &.severity-lvl-1 {
          fill: $sev-color-1;
          color: $sev-color-1;
        }

        &.severity-lvl-2 {
          fill: $sev-color-2;
          color: $sev-color-2;
        }

        &.severity-lvl-3 {
          fill: $sev-color-3;
          color: $sev-color-3;
        }

        &.severity-lvl-4 {
          fill: $sev-color-4;
          color: $sev-color-4;
        }

        $key-color: gold;

        &.key {
          stroke: $key-color; //$first-color;
          fill: rgba(0, 0, 0, .5); // lighten($first-color, 40%);
          stroke-width: 2px;
        }

        &.hallmark {
          stroke: rgb(38, 172, 222); //$key-color; //$worst-color;
          fill: rgba(0, 0, 0, .5); //lighten($worst-color, 40%);
          stroke-width: 2px;
        }


      }
    }

    .eventTimelineAxisY {
      stroke: darkgoldenrod;

    }

    .eventTimelineAxisYText {
      fill: rgba(0, 0, 0, 1);
    }

    .eventGraphRows {


      .eventGraphRow {

        &:nth-child(odd) {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }
  }

  .scrollable {
    @extend %custom-scrollbar-light;
  }

  .tooltip {

    background: #fff;
    border: 1px solid #ccc;
    color: rgb(0, 0, 0);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .2);

    .severity-lvl-1 {
      fill: $sev-color-1;
      color: $sev-color-1;
      margin-right: $sm;
    }

    .severity-lvl-2 {
      fill: $sev-color-2;
      color: $sev-color-2;
      margin-right: $sm;
    }

    .severity-lvl-3 {
      fill: $sev-color-3;
      color: $sev-color-3;
      margin-right: $sm;
    }

    .severity-lvl-4 {
      fill: $sev-color-4;
      color: saturate($sev-color-4, 20%);
      margin-right: $sm;
    }


    .logs {
      color: $logs-color;
      margin-right: $sm;
    }

    .hosts {
      color: $hosts-color;
      margin-right: $sm;
    }

    .timestamp {
      color: $title-color;
    }

    .firstIcon {
      color: $first-color;
    }

    .worstIcon {
      color: $worst-color;
    }
  }



  .histogram {

    background: #fff; //rgb(182, 195, 202);

    .histogramLabelStart,
    .histogramLabelEnd {
      fill: #000;
    }


    .histogramAxis {
      stroke: rgba(0, 0, 0, .25);

    }

    .histogramBar {
      fill: rgba(0, 0, 0, .5);

      &:hover {
        fill: rgba(0, 0, 0, .8);
      }

    }


  }
}