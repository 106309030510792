@import '../../../node_modules/athena-next-ui-lib/dist/global-vars';

.sidePaneColumn {
  overflow-x: hidden;
  overflow-y: auto;
  @extend %custom-scrollbar;
}

.mainPaneColumn {
  overflow: hidden;
}

.rejected {
  background: $alert-rejected-bg;
  color: $alert-rejected-txt;
  padding: 0 8px;
}

.suggested {
  background: $alert-suggested-bg;
  color: $alert-suggested-txt;
  padding: 0 8px;
}



//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {
  .sidePaneColumn {
    @extend %custom-scrollbar-light;
  }
}