@import '~athena-next-ui-lib/dist/_global-vars.scss';


$yellow: #e8c819;
$black: #000;
$duration: .15s;
$bar-bg: #4E5154;



.findBar {
  font: $fnt-main;
  font-size: $fnt-md;
  position: sticky;
  bottom: 0;

  display: flex;
  align-items: center;

  flex: 0 1;

  background: $bar-bg;
  border-top: 1px solid #888;

  transition: $list-mode-transition;


  &.read {
    background: $list-mode-read-bg;
    color: $list-mode-read-txt;
  }

  &.edit {
    background: $list-mode-read-bg;
    color: $list-mode-read-txt;
  }

  &.peek {
    background: $list-mode-read-bg;
    color: $list-mode-read-txt;
  }


  .forceRight {
    margin-left: auto;
  }


  .controls {
    margin: $md $lg;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  label {
    color: #fff;
    margin-right: $md;
  }


  .field {
    position: relative;
    border-radius: 24px 0 0 24px;
    display: flex;
    align-self: center;


    input {
      border-radius: 2px 0 0 2px;
      border: 1px solid rgba(255, 255, 255, .35);
      background: rgba(255, 255, 255, .25);
      color: $find-color; //#fff;


      &::placeholder {
        color: rgba(255, 255, 255, .6)
      }

      padding: $sm - 1 $lg;
      outline: none;
      width: 250px;

      &:focus,
      &.notEmpty {
        background: rgba(0, 0, 0, .45);
      }

    }

    .clearField {
      position: absolute;
      height: 100%;
      width: 12px;
      z-index: 1;
      right: 8px;
      top: 3px;
      color: #fff;
    }
  }


  button {
    border: 1px solid rgba(255, 255, 255, .35);
    border-width: 1px 1px 1px 0;
    background: rgba(255, 255, 255, .25);
    border-radius: 0;
    color: #fff;
    padding: $sm $md;
    margin: 0;



    &:hover {
      background: rgba(255, 255, 255, .1);
      cursor: pointer;
    }

    &[disabled] {
      opacity: .5;
      color: rgba(255, 255, 255, .5);
      cursor: not-allowed;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }

}

.resultCount {

  padding: $sm $lg;
  color: rgba(255, 255, 255, .9);
  font: $fnt-main;
  font-size: $fnt-md;
  text-transform: uppercase;

  &.peeking {
    color: $yellow;
  }


  b {
    color: #ccc;
    background: black;
    display: inline-block;
    padding: 0 6px;
    margin: 0 3px;
    border-radius: 2px;
    font-weight: normal;
  }

  u {
    color: $search-color;
    text-transform: none;
    text-decoration: none;
  }
}


body:global(.darkTheme) {}

body:global(.lightTheme) {

  .findBar {
    color: rgb(0, 0, 0);
    background: rgb(192, 192, 192);
    border-top-color: rgb(153, 153, 153);
    margin-top: -1px;

    .resultCount,
    label {
      color: rgb(0, 0, 0);
    }

    button {
      color: rgb(0,0,0);

      &[disabled] {
        color: rgba(0, 0, 0, .5);
      }
    }

    .field {
      input {
        background: rgb(255, 255, 255);
        border: 1px solid rgba(0, 0, 0, .35);
        color: rgb(0, 0, 0);

        ::placeholder {
          color: rgb(153, 153, 153);
        }
      }

      .clearField {
        color: #000;
      }
    }


  }
}