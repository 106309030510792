
@import '~athena-next-ui-lib/dist/_global-vars.scss';

.alertRuleTag {
  padding: 1px 4px;
  border: 1px solid rgba(255, 255, 255, .1);
  margin:2px 4px;

  &.user_driven, &.custom {
    background: steelblue;
    color: #fff;
  }

  &.manual {
    background: gray;
    color: #fff;
  }

  &.evts_inc {
    background: gray;
    color: #fff;
  }

  &.routing {
    color: #fff;
    background: #998000;
  }
}