@import '~athena-next-ui-lib/dist/_global-vars.scss';


$toolbar-bg-1: rgb(34, 34, 34);
$toolbar-bg-2: rgb(58, 62, 65);
$match-color: #ff9632;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  padding: 10px;
  background: linear-gradient(180deg, $toolbar-bg-1 10%, $toolbar-bg-2 95%);
  max-height: 25vh;

}

.graph {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;


  overflow-y: auto;
  overflow-x: hidden;
  max-height: 25vh;

  @extend %custom-scrollbar;

  margin: 0;
  padding: 0;
  flex: 1 0;

  .graphOuter {
    padding: 0;
    margin: 0;
    display: flex;
  }
}

$light-gray: rgba(255, 255, 255, .4);

.xAxisLine {
  stroke: $light-gray;
  stroke-width: 1px;
  fill: rgba(255, 255, 255, .1);
}

.xAxisLineShadow {
  stroke: rgba(0, 0, 0, .3);
  stroke-width: 1px;
  fill: transparent;
}

.xAxisStartLabel,
.xAxisEndLabel {
  font: $fnt-main-mono;
  font-size: $fnt-xs;
  //font-weight: bold;
  fill: $blue;
  text-anchor: middle;
}

.xAxisEndLabel {
  text-anchor: end;
}

.xAxisTimeBinLabel {

  font: $fnt-main-mono;
  font-size: $fnt-xs;
  fill: $title-color; //#fff;
  text-anchor: start;

  &.anchorEnd {
    text-anchor: end;
  }
}


.dot,
.triangle {
  fill: #828383; //rgba(255, 255, 255, .25);
  stroke: #828383; //rgba(255, 255, 255, .25);
  stroke-width: 1px;

  cursor: pointer;

  &.suggested {
    fill: $alert-suggested-bg;
    stroke: $alert-suggested-bg;
  }

  &.rejected {
    fill: lighten($alert-rejected-bg, 20%);
    stroke: lighten($alert-rejected-bg, 20%);
  }


  &.selected {
    fill: $title-color;
    stroke: $title-color;

    &.matched {
      fill: $match-color;
      stroke: $match-color;
    }
  }

  &.matched {
    fill: $match-color;
  }

  &:hover,
  &.matched:hover {
    transform: scale(1.25);
    transform-origin: 8px -4px;
    stroke-width: 2px;
    stroke: white;
  }
}

.cssDot {
  background: salmon;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.timeBin {

  >rect,
  >path {
    fill: rgba(255, 255, 255, 0);
    stroke: rgba(255, 255, 255, .2);
    stroke-width: 0;
    transition: .2s fill ease-in;
  }



  &.selected {

    >rect,
    >path {
      fill: rgba(255, 255, 255, .1);
      stroke: rgba(255, 255, 255, .2);
      stroke-width: 0;
    }
  }
}


.prompt {
  text-align: center;
  color: rgba(255, 255, 255, .85);

  b {
    color: $title-color;
  }
}


.topToolbar,
.btmToolbar {
  position: sticky;
  z-index: 1;
  display: flex;
  flex-direction: row;
  color: #fff;
  width: 100%;

}

.topToolbar {
  top: 0;
  //position: absolute;

  z-index: 2;
  //background: $toolbar-bg-1;
}

.btmToolbar {
  //position: relative;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  flex: 0 1;
  background: $toolbar-bg-2;
}

.floatingButtons {
  position: absolute;
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  transition: .25s top ease-in;
  top: 30px;

  &.visible {
    top: 0;
  }

  .incidents {

    color: $title-color !important;

  }

  .matches {
    color: $match-color !important;
  }

  span {
    font-size: $fnt-sm;
    color: #fff;
    margin-right: $md;
  }

}

.timeBinSelector {
  rect {
    fill: rgba(255, 255, 255, .2);
  }

  path {
    fill: #5b5b5b; //rgba(255, 255, 255, .2);
    stroke: rgba(0, 0, 0, .5);
    stroke-width: 1px;
  }

  line {
    stroke: rgba(255, 255, 255, .25);
    stroke-width: 1px
  }

  text {
    font: $fnt-main;
    font-size: 10px;
    fill: rgba(255, 255, 255, .75);
    text-anchor: middle;
    dominant-baseline: middle;
  }

  rect.bg {
    filL: transparent;
  }

  &.timeBinSelectorSelected {
    path {
      fill: black;
      stroke: rgba(255, 255, 255, .5);
      stroke-width: 1px;
    }

    text {
      fill: $title-color;
    }

    rect.bg {
      filL: #404040;
    }
  }



  &:hover {
    cursor: pointer;

    rect {
      fill: darken($title-color, 10%);
    }

    path {
      fill: darken($title-color, 20%);
      stroke: #fff;
      stroke-width: 1px;

    }

    text {
      fill: #fff;
    }
  }
}


.incidentTooltip {
  position: absolute;
  z-index: 3000;
  display: flex;
  font: $fnt-main;
  font-size: $fnt-sm;
  //background: $tooltip-bg;
  //border: 1px solid $tooltip-border;
  background: #254156;
  border: 1px solid #37607e;
  color: #fff;

  padding: 1px 2px;
  white-space: nowrap;
  align-content: flex-start;
  flex-direction: column;

}



.tooltipBody {

  display: flex;
  flex-direction: column;
  padding: $sm $md;
  word-break: break-word;

  .suggested {
    align-self: flex-start;
    font-weight: bold;
    background: $alert-suggested-bg;
    color: $alert-suggested-txt;
    padding: 2px 4px;
  }


  .rejected {
    align-self: flex-start;
    font-weight: bold;
    background: lighten($alert-rejected-bg, 10%);
    color: $alert-rejected-txt;
    padding: 2px 4px;
  }

  .title {
    font-size: $fnt-md;
    font-weight: bold;
  }

  .timestamp {
    font: $fnt-main-mono;
    background: #000;
    align-self: flex-start;
    padding: $xs $sm;
    font-weight: bold;

  }

  span {
    white-space: normal;

    +span {
      margin-top: $sm;
    }
  }
}



/*beacon*/

.beacon {
  $duration: 4s;
  $startScale: .5;
  $endScale: 5;

  transition: .25s all ease-in-out;

  .beaconHalo {
    fill: transparent; //rgba(0,0,0,.5);
  }

  .beacon1 {
    fill: transparentize($blue, .15);
    transform: scale($startScale);
    //transform-origin: center center;
    animation: beacon-anim $duration linear infinite;
  }

  .beacon2 {
    @extend .beacon1;
    animation-delay: $duration * 0.3;
  }

  .beacon3 {
    @extend .beacon1;
    animation-delay: $duration * 0.6;
  }

  @keyframes beacon-anim {
    0% {
      transform: scale($startScale);
      opacity: .25;
    }

    50% {
      opacity: 0.1;
    }

    70% {
      opacity: 0.09;
    }

    100% {
      transform: scale($endScale);
      opacity: 0;
    }
  }
}


.eventSummary {
  margin: 10px 20px;
  padding: 10px;

  font: $fnt-main-mono;
  font-size: $fnt-md;
  background: rgba(0, 0, 0, .2);
  color: rgba(255, 255, 255, .9);
  border: 1px solid rgba(255, 255, 255, .5);
}


//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {

  .eventSummary {
    background: rgba(0, 0, 0, .1);
    color: rgb(0, 0, 0);
  }
}