@import '~athena-next-ui-lib/dist/_global-vars.scss';

.page{
  color:#fff;
  display: flex;
  flex-direction: column;
  width:96vw;

  .link {
    font-size: $fnt-lg;
  }

}

.subTabContent{
  padding:20px;
  background: linear-gradient(180deg, #71791e 0%, transparent 1%)
}

