@import '~athena-next-ui-lib/dist/_global-vars.scss';

.wordCloud {
  flex: 1 0 100%;
  padding: $sm 0;
  text-align: center;
  align-self: center;
  border-radius: 5px;

  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  line-height: .8;
  margin: 0 $sm;
  font-family: 'Passion One', Helvetica, sans-serif;
  //background: rgb(194, 194, 194);

  >div {

    align-self: center;

  }

  //font:$fnt-main-title;
  &:hover {
    //background:rgba(0,0,0,.3);
  }


  &.scrollable {
    overflow-x: hidden;
    overflow-y: auto;

    @extend %custom-scrollbar;
  }

  a {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 0 $md - 1;
    white-space: nowrap;
    border: 1px solid transparent;
    transition: .15s all ease-in;
    line-height: .8;

    &:hover {
      border: 1px solid $blue;
      //text-shadow:0 5px 3px rgba(0,0,0,.3);
      //transform: scale(1.2);
    }
  }

}