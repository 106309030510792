@import '../../../node_modules/athena-next-ui-lib/dist/global-vars';

.snake {
  fill: transparent;
  //stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1.5px;
}

.snakeDot {
  fill: #fff; //#ccc;
  stroke: #575b5f;
  stroke-width: 1.5px; //1px;
  transition: all 0.25s ease-in;

  &.selected {
    fill: rgb(234, 175, 5);
    stroke: #fff;
    stroke-width: 2px;
    transform: scale(1.05); // rotate(45deg);
  }

  &:hover {
    cursor: pointer;
    stroke: #fff;
    stroke-width: 2px;
    fill: rgb(234, 175, 5);
  }
}

.occurrences-container {
  width: 100%;
}

.tooltip {
  $bg: $tooltip-bg;
  position: absolute;
  z-index: $z-index-tooltip;
  background: $bg;
  border: 1px solid $tooltip-border;

  color: rgb(255, 255, 255);
  border-radius: $sm;

  word-break: break-all;
  padding: $sm;
  max-height: 20vh;
  max-width: 60vw;
  overflow: auto;
  font: $fnt-main-mono;

}

.scrollable {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  max-height: 50vh;

  @extend %custom-scrollbar;

  .stickyLeft {
    position: sticky;
    left: 0;
    z-index: 0;
    opacity: .25;

    .top {
      position: absolute;
      top: 0;
      left: $sm;
    }

    .btm {
      position: absolute;
      top: 20px;
      left: $sm;
    }
  }

  .svgGraph {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.dayLabel {
  fill: rgba(255, 255, 255, .5);
  font: $fnt-main;
  font-size: $fnt-xxs;
  text-anchor: start;
}

.dayLabelLine {
  stroke: rgba(255, 255, 255, .5);
  stroke-width: 1px;
  stroke-dasharray: 3;
}

.exceedLimitMsg {
  font-size: $fnt-sm;
  color: goldenrod;
  display: flex;
  align-items: center;
  flex-direction: row;
}

//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {

  .tooltip {
    background: #fff;
    color: #000;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .2);

  }

  .scrollable {
    @extend %custom-scrollbar-light;
  }

  .dayLabel {
    fill: rgba(0, 0, 0, .5);
  }

  .dayLabelLine {
    stroke: rgba(0, 0, 0, .5);
  }
}