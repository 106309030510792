@import '~athena-next-ui-lib/dist/_global-vars.scss';


$yellow: #e8c819;
$black: #000;
$duration: .15s;
$bar-bg: #4E5154;

.listWrapper {
  position: relative;
  border: 2px solid transparent;

  display: flex;
  flex-direction: column;
  flex: 1 0;
  align-self: stretch;

  &.read {
    border-color: $list-mode-read-color
  }

  &.edit {
    border-color: $list-mode-edit-color
  }

  &.peek {
    border-color: $list-mode-peek-color
  }

  transition: $list-mode-transition;

}

.event-list {
  //margin: 5px;
}

.promptToScrollButton {
  $bg: #cb73d0;
  position: absolute;
  z-index: 100;
  top: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex: 0 1 auto;
  margin: auto;
  width: auto;
  height: auto;
  text-align: center;


  background: $bg;
  color: #fff;
  font-size: $fnt-sm;
  border-radius: 25px;
  padding: 2px 8px;
  border: 2px solid #fff;
  text-transform: uppercase;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, .5);
  transition: .25s all ease-out;
  flex-wrap: nowrap;
  white-space: nowrap;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
    background: darken($bg, 10%);
    //box-shadow: 0 0 5px 5px rgba(255,255,255,.1);
    box-shadow: 0 0 5px 5px rgba(72, 164, 231, 0.25);
  }

  &.hide {
    top: -100px;
    opacity: 0;
  }
}


.event {
  +.event {
    margin-top: $sm;
    padding-top: $sm;
    border-top: 1px solid rgba(255, 255, 255, .1);
  }
}

.loading {
  margin: $xl;
}

.error {
  padding: 0 20px;
  color: $lite-8;

  h3 {
    margin: $md 0 0;
    color: goldenrod;
  }

  p {
    margin: $md 0;
  }
}

.noData {
  font: $fnt-main;
  padding: 20px 10px;
  color: goldenrod;
  font-weight: bold;
  font-size: $fnt-md;

  display: flex;
  flex-direction: column;
}


.cornerRibbon {
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -0px;
  text-align: center;

  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  font: $fnt-main-title;
  font-size: $fnt-sm;
  line-height: 30px;
  text-transform: uppercase;
}

/* Custom styles */

.cornerRibbonOuter {
  display: inline-block;
  top: 0;
  right: 0;
  position: absolute;
}

.cornerRibbon.sticky {
  position: absolute;
  right: -10px;
  z-index: 6;

}

.cornerRibbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

/* Different positions */

.cornerRibbon.topLeft {
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.cornerRibbon.topRight {
  top: 35px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.cornerRibbon.bottomLeft {
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.cornerRibbon.bottomRight {
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Colors */

.cornerRibbon.white {
  background: #f0f0f0;
  color: #555;
}

.cornerRibbon.black {
  background: #333;
}

.cornerRibbon.grey {
  background: #999;
}

.cornerRibbon.blue {
  background: #39d;
}

.cornerRibbon.green {
  background: #2c7;
}

.cornerRibbon.turquoise {
  background: #1b9;
}

.cornerRibbon.purple {
  background: #95b;
}

.cornerRibbon.red {
  background: #e43;
}

.cornerRibbon.orange {
  background: #e82;
}

.cornerRibbon.yellow {
  background: #ec0;
}

.cornerRibbon.golden {
  background: goldenrod;
}

//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {

  .listWrapper {
    color: rgb(0, 0, 0);
  }
}