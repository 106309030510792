@import '~athena-next-ui-lib/dist/_global-vars.scss';


html,
body {

  padding: 0;
  margin: 0;
  font: $fnt-main;
  height: 100%;
  overflow: hidden;
}

#__next {
  display: flex;
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;

  .main-view {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;

    &.h-layout {
      flex-direction: row;
      overflow: hidden;
    }

    &.scrollable {
      overflow-y: auto;
      overflow-x: hidden;
      @extend %custom-scrollbar;
    }

  }

}

.panel {
  background: $lite-2;
  margin: $md $md;
  color: $lite-10;
  border-radius: $xs;
  padding: $md;

}

.container {

  height: 100%;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  justify-content: flex-start;

  &.scroll-v {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    overflow-y: auto;
    overflow-x: hidden;
    @extend %custom-scrollbar;
  }


}

button {
  display: inline-flex;
  border: 1px solid $lite-3;
  background: transparent; //$lite-3;
  color: $lite-8;
  margin: 0 $xs;
  border-radius: $xs;
  min-width: 40px;
  justify-content: center;


  &.selected {
    outline: none;
    background: #498ACA; //$blue;
    color: $lite-10;
    border-color: $lite-8;
  }
}

.link {
  color: #7fbff5; //#87b0ce;
  cursor: pointer;
  transition: $fast-anim;

  &:hover {
    transform: scale(1.1)
  }
}


.back-btn-column {
  color: $lite-10;
  display: flex;
  flex: 1 0 0;
  align-items: flex-start;
  justify-content: center;
  border-right: 1px solid $lite-1;
  height: 100%;
  background: linear-gradient(90deg, rgb(51, 83, 103) 0%, rgba(255, 255, 255, 0) 100%);

  >* {
    margin-top: $xl;
  }

}




.page-not-found {
  $c: #9ca3a7;
  color: $c;
  font-size: $fnt-xxl;
  margin: 100px auto;
}


.no-permission-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  .page-no-permission {
    $c: #9ca3a7;
    color: $c;
    font-size: $fnt-xl;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto 15px;
  }

  .no-permission-detail {
    color: salmon;
    font-size: $fnt-lg;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;
  }
}

.about-page {
  $c: #9ca3a7;
  color: $c;
  padding: 20px;
  width: 50vw;
  font-size: $fnt-lg;
  white-space: nowrap;

}

.copyright {
  color: #9ca3a7;
  margin: 0 auto;
}


.blink {
  animation: blink-anim .6s both
}

@keyframes blink-anim {

  0%,
  50%,
  100% {
    opacity: 1
  }

  25%,
  75% {
    opacity: 0
  }
}


//Theming
body.darkTheme {
  background: $app-bg;

  .page {
    color: rgb( 255, 255, 255);
  }

  .about-page,
  .copyright {
    color: rgb(156, 163, 167);
  }
  
}

body.lightTheme {
  background: $app-bg-light;
  .page {
    color: rgb(0, 0, 0);
  }

  .about-page,
  .copyright {
    color: rgba(0, 0, 0, 0.8);
  }
}