@import '~athena-next-ui-lib/dist/_global-vars.scss';

$hover-color: rgba(255, 255, 255, .05); //#515e69;
$row-color: #46494D; //rgba(167, 167, 167, 0.2);

$category-color: #adaef1; //#9a9bf0; //orange;

$yellow: #e8c819;
$black: #000;
$duration: .15s;

$alert-custom-bg: #3b7bb0;
$manual-tag-bg: #975085;

.incidentList {
  padding: 0;

  &.notGroupedByDay {
    padding-left: $lg;
  }
}

.incidentRow {
  position: relative;
  display: block;

  font: $fnt-main-mono;
  color: #f0f0f0; //$lite-9;

  border-radius: 0 $sm $sm 0;

  word-break: break-word;

  border: 1px solid transparent;

  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, .1);

  padding: 0 $md;
  margin: 0;
  width: 100%;

  &:hover {
    border-color: $title-color;
    background: $hover-color;
    border-style: dashed;
    cursor: pointer;
  }


  .experimental {
    $h: 9px;
    $bg: #7a7a7a;
    position: relative;
    margin: 0 $h + 6 1px 0;
    background: $bg;
    color: #efe;
    padding: 3px $sm 3px $h + 2;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 700;
    white-space: nowrap;


    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      right: -$h;
      border-left: $h - 4 solid #272C30;
      border-top: $h solid transparent;
      border-bottom: $h solid transparent;
    }

    &:before {
      content: '';
      position: absolute;
      right: -$h + 2;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: $h - 2 solid $bg;
      border-top: $h solid transparent;
      border-bottom: $h solid transparent;
    }

    >div {
      text-transform: none;
    }
  }

  .title {
    word-break: break-all;
    font: $fnt-main-title;
    font-size: $fnt-lg;
    color: $lite-10;
  }

  .subtitleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .subtitle {
    font-weight: 700;
    display: flex;
    //flex-direction: row;
    align-items: center;
    margin: $xs 0;
    flex-wrap: wrap;


    >* {
      margin-right: $md;
    }


    .group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      >* {
        margin-right: $md;
      }
    }

  }

  .occurrenceCount {
    background: rgba(141, 190, 218, 0.4);
    color: $lite-10;
    padding: $sm - 1 $md;
    border-radius: $xs;
    display: inline-flex;
    align-items: center;
    //text-transform: uppercase;
    font-size: $fnt-xs;
    //white-space: nowrap;
  }

  .doesMatchIcon,
  .doesNotMatchIcon {
    font-size: 14px;
  }

  .doesMatchIcon {
    color: darkorange;
  }

  .doesNotMatchIcon {
    color: $title-color;
  }


  .logs {
    color: $logs-color;
    padding-right: $md;
  }

  .hosts {
    color: $hosts-color;
    padding-right: $md;
  }

  .category {
    color: $category-color;
    padding-right: $md;
  }

  .serviceGroups {
    color: $service-groups-color;
    padding-right: $md;
  }

  .datestamp {
    color: #7ca1b6;
    font-weight: bold;
  }


  .timeDim {
    color: darken($title-color, 20%);
  }

  .timeBright {
    color: $title-color;
  }

  .events {
    margin: $md 0;
    border-radius: $sm;
    line-height: $fnt-lg;
    width: 100%;
    color: $lite-10;
    background: $lite-1;
  }


  &.incidentRowRejected {
    $h: 9px;
    $bg: lighten($alert-rejected-bg, 20%);
    border: 1px solid $bg;

    .rejected {

      position: relative;
      margin: 0 $h + 6 1px 0;
      background: $bg;
      color: $alert-rejected-txt;
      padding: 3px $sm;
      text-transform: uppercase;
      font-size: 9px;
      font-weight: 700;
      white-space: nowrap;

      //&:after {
      //  content: '';
      //  position: absolute;
      //  left: 0;
      //  bottom: 0;
      //  width: 0;
      //  height: 0;
      //  right: -$h;
      //  border-left: $h - 4 solid #272C30;
      //  border-top: $h solid transparent;
      //  border-bottom: $h solid transparent;
      //}
      //
      //&:before {
      //  content: '';
      //  position: absolute;
      //  right: -$h + 2;
      //  bottom: 0;
      //  width: 0;
      //  height: 0;
      //  border-left: $h - 2 solid $bg;
      //  border-top: $h solid transparent;
      //  border-bottom: $h solid transparent;
      //}

      >div {
        text-transform: none;
      }
    }
  }

  &.incidentRowSuggested {
    margin: 10px 0;
    border: 1px solid gold;
    background: repeating-linear-gradient(-45deg,
        transparent,
        transparent 10px,
        rgba(255, 255, 255, .025) 10px,
        rgba(255, 255, 255, .025) 20px), rgba(0, 0, 0, .4);



  }

  .peekToggle {
    display: flex;
    //transform: translateX(0px);
  }

  .metadataToggle {
    visibility: visible;
  }

  .titlebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 1 70%;
  }


  .accepted {
    $h: 9px;
    $bg: darken($title-color, 20%);
    position: relative;
    margin: 0 $h + 6 1px 0;
    background: $bg;
    color: #fff;
    padding: 3px $sm;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 700;
    white-space: nowrap;

    >div {
      text-transform: none;
    }

  }

  .suggested {
    $h: 9px;
    $bg: $alert-suggested-bg;
    position: relative;
    margin: 0 $h + 6 1px 0;
    background: $bg;
    color: $alert-suggested-txt;
    padding: 3px $sm;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 700;
    white-space: nowrap;


    //&:after {
    //  content: '';
    //  position: absolute;
    //  left: 0;
    //  bottom: 0;
    //  width: 0;
    //  height: 0;
    //  right: -$h;
    //  border-left: $h - 4 solid #272C30;
    //  border-top: $h solid transparent;
    //  border-bottom: $h solid transparent;
    //}

    //&:before {
    //  content: '';
    //  position: absolute;
    //  right: -$h + 2;
    //  bottom: 0;
    //  width: 0;
    //  height: 0;
    //  border-left: $h - 2 solid $bg;
    //  border-top: $h solid transparent;
    //  border-bottom: $h solid transparent;
    //}

    >div {
      text-transform: none;
    }
  }


  .resultOfScanIcon {
    //position: absolute;
    //top: 0;
    //left: -25px;
    padding: 4px 6px;
    margin: 2px 0 0 1px;
    background: $btn-green;
    color: #fff;
    border-radius: 48px;
    font-size: 12px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 1px 1px rgba(0, 0, 0, .5);


    &.hidden {
      visibility: hidden;
    }


  }

  .eventWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }


  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $sm $sm;
    width: 100%;
  }

}

.incidentEvent {
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: break-all;
  padding: $sm;
  line-height: 1.5;

  flex: 1 1 100%;

  .icon {
    position: relative;
    margin-right: $md;
  }

  +.incidentEvent {
    border-top: 1px solid $lite-3;
  }

  //search term match
  b {
    font-weight: normal;
    background: $search-color; //#11BEEA;
    color: #000;
    text-decoration: none;
  }

  //find term match
  u {
    background: $find-color; //#11BEEA;
    color: #000;
    text-decoration: none;
  }
}

.rowGroup {

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  background: linear-gradient(90deg, transparent 0, transparent 19px, rgba(255, 255, 255, .1) 19px, rgba(255, 255, 255, 0) 32px);

  &.noBg {
    background: none;
    padding-left: $md;
  }

  &:last-child {
    padding-bottom: 60px;
  }

  .dateTime {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    top: $md;
    z-index: 1;
    color: $title-color;
    padding: 0 $sm 0 $sm;
    font: $fnt-main-mono;
    font-size: $fnt-xs;
    border-radius: $sm;
    margin: 2px 0 0 0;

    text-orientation: sideways;
    writing-mode: vertical-lr;
    text-transform: uppercase;
    transform: scale(-1, -1);

    .month {
      display: flex;
      justify-content: center;
      border-radius: $sm $sm 0 0;
      font-weight: 700;
      background: $row-color;
      font-size: $fnt-sm;
      text-transform: uppercase;

      padding: 8px 0 0 0;

    }

    .day {
      display: flex;
      justify-content: center;
      border-radius: 0;
      border: 2px solid $row-color;

      font-size: $fnt-md;
      font-weight: 700;
      background: black;

      padding: 0 0 8px 0;

    }

    .date {
      position: relative;
      color: $lite-5; //$title-color;
      font-weight: 700;
      font-size: $fnt-xs;
      padding: $sm 0 $md;
      border-radius: 0 0 4px 0;

      background: rgba(255, 255, 255, .1);


      &:after {
        $size: 14px;
        $bg: rgba(255, 255, 255, .1);
        content: "";

        position: absolute;
        top: -$size;
        width: 0;
        height: 0;

        border-style: solid;
        border-width: $size 0 0 $size;
        border-color: transparent transparent transparent $bg;
      }

    }

  }

  .rowGroupRows {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &:hover {
    .date {
      color: $title-color;
    }
  }


}


.scrollContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  @extend %custom-scrollbar;

  padding-bottom: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.timestamp {
  color: $title-color;
  font-weight: 700;
  //font-size:$fnt-md;
  //background: $dark-8;
  padding: $xxs 0;
  border-radius: $xs;
  //white-space: nowrap;

  padding-right: $md;


}


.logs {
  color: $logs-color;
  padding-right: $md;
}

.hosts {
  color: $hosts-color;
  padding-right: $md;
}

.serviceGroups {
  color: $service-groups-color;
  padding-right: $md;
}

.body {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;

  .eventList {
    display: flex;
    flex: 1 0;
  }

  .wordCloud {
    display: flex;

  }
}

//significance
//.icoSignificance {
//  position: relative;
//  display:inline-block;
//  width:24px;
//  height:24px;
//  margin-right:12px;
//  font-size:1.2em;
//  transition: 0.1s all ease-in;
//
//  &:hover {
//    transform: scale(1.1);
//    filter: saturate(5);
//  }
//
//  &.low {
//    .icoSignificanceBase {
//      color: rgba(34, 168, 210,.5);
//      font-size: 2.2em;
//      margin-top: -1px;
//    }
//    .icoSignificanceSymbol {
//      color: rgb(34, 168, 210); //rgba(255, 255, 255, 0.4);
//      font-size: 1.3em;
//      margin: 5px 7px;
//    }
//  }
//  &.medium {
//    .icoSignificanceBase {
//      color: rgba(255, 204, 102, .5); //rgba(255, 255, 255, 0.2);
//      font-size: 2.2em;
//      margin-top: -1px;
//    }
//    .icoSignificanceSymbol {
//      color: rgba(255, 204, 102, 1);
//      font-size: 1.3em;
//      margin: 4px 6px;
//    }
//  }
//
//  &.high {
//    .icoSignificanceBase {
//      color: rgba(255, 96, 1, .5); //rgba(255, 96, 1, 1);
//      font-size: 2.2em;
//      margin-top: -1px;
//    }
//    .icoSignificanceSymbol {
//      color: rgba(255, 96, 1, 1); //rgba(255, 204, 102, 1);
//      font-size: 1.3em;
//      margin: 4px 8px;
//    }
//  }
//}


.resultOfScan {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  font-size: 1.2em;
  transition: 0.1s all ease-in;

  .resultOfScanBase {
    position: relative;
    color: transparentize($btn-green, .4);
    font-size: 2.2em;
  }

  .resultOfScanSymbol {
    position: relative;
    color: white;
    font-size: 1em;
    padding: 11px;
  }
}


.incidentIndicators {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  display: flex;
  flex-direction: column;
  //justify-content: center;


  >* {
    +* {
      margin-top: $md;
    }
  }

}

.tagType {
  $manual: #2680d0;
  position: relative;
  display: inline-flex;
  align-items: center;
  font: $fnt-main-mono;
  margin: 0 $md 0 0;
  border: 1px solid rgba(255, 255, 255, .4);

  color: rgba(255, 255, 255, .9);
  justify-content: center;
  font-weight: bold;
  transition: .15s all ease-in;

  padding: 1px $sm;
  border-radius: 2px;
  white-space: nowrap;



  &.manual {


    background: $manual-tag-bg;

    &:after {
      width: 8px;
      height: 8px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid $manual;
    }

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }

  &.auto {
    background: $alert-custom-bg;
  }

  &.user_driven {
    background: $alert-custom-bg; //#6452a4
  }

  &.routing {
    background: #913a6b;
  }



  &.addTag {
    background: $manual-tag-bg;
    padding: 2px $sm;
    font-size: $fnt-sm;

    .plus {
      margin-right: 2px;
    }

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}


.tagSelector {
  padding: 0;
  max-width: 40vw;

  .tagCheckbox {
    $size: 17px;

    display: flex;
    align-items: center;
    margin-right: $size *0.25;
    margin-top: 2px;
    margin-bottom: 2px;

    input[type=checkbox] {
      margin-right: $size * 0.25;
      height: $size;
      width: $size;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      border: none;
      border-radius: 2px;
      outline: none;
      transition-duration: 0.3s;
      background-color: rgba(255, 255, 255, .2);
      cursor: pointer;

    }

    &:hover {
      input[type=checkbox] {
        background-color: rgba(255, 255, 255, .5);
      }
    }

    input[type=checkbox]:checked {
      border: 1px solid rgba(255, 255, 255, .5);
      background-color: rgba(255, 255, 255, 0);
    }


    .tagType {
      &:hover {
        transform: none !important;
      }
    }

    &.disabled {
      .tagType {
        //filter: grayscale(1);
        background: transparent !important;
      }
    }


    input[type=checkbox]:checked {
      +.tagType {

        &::before {
          content: '\2715';
          display: block;
          text-align: center;
          color: #fff;
          position: absolute;
          left: -$size - 2;
          top: -6px;
          //font-weight: bold;
          font-size: $size + 2;
        }
      }

    }
  }

  .tagRow {
    padding: $md $md;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    p {
      margin: 0
    }

    >div {
      max-width: 40vw;
      padding: $md 0;
      display: flex;
      flex-direction: row;
      justify-items: flex-start;
      flex-wrap: wrap;
    }


  }
}

.matchesLabel {
  font-size: 13px;
  color: #62c28c; //rgba(255,255,255,.5);
  font-style: italic;
  font-weight: normal;
  padding: 0 8px 0 0;
}







//Theming
body:global(.darkTheme) {}

body:global(.lightTheme) {

  .incidentRow {
    color: rgb(0, 0, 0);

    border: 1px solid rgba(0, 0, 0, .1);

    &.incidentRowSuggested {
      background: transparent;
    }

    &:hover {
      background: rgba(0, 0, 0, .1);
    }

    .occurrenceCount {
      background: rgba(141, 190, 218, 1);
    }

    .title {
      color: rgb(0, 0, 0);
    }
  }

  .incidentEvent {
    color: rgb(0, 0, 0);
  }



  .logs,
  .hosts,
  .category,
  .serviceGroups,
  .timestamp,
  .datestamp {
    filter: brightness(80%);
  }

  .rowGroup {
    .datetime {
      .date {
        color: rgba(0, 0, 0, .4);
      }
    }
  }
}