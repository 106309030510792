@import '~athena-next-ui-lib/dist/_global-vars.scss';


.page {
  width: 800px;
  color: #fff;
  padding: 20px;
}

.dialogContent {
  max-height: 60vh;
  @extend %custom-scrollbar;
}

.section {
  margin: 0 $md 20px;

  .sectionTitle {
    font: $fnt-main-title;
    flex-direction: row;
    align-items: center;
    color: $title-color; //saturate($blue, 10%); //rgba(255, 255, 255, 1);
    font-size: 13px;
    font-weight: normal;

    .sectionStep {
      display: inline-flex;
      border-radius: 24px;
      font-size: 16px;
      color: rgba(255, 255, 255, 1);
      padding: $xs $md + 2;
      background: transparentize($blue-dk, .25); //rgba(255,255,255,.2);
      margin-right: $md;
      font-weight: bold;
    }
  }

  .sectionBody {
    margin-left: 30px;
    margin-bottom: 10px;

    &.row {
      flex-direction: row;
    }
  }
}

.channels {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: rgba(255, 255, 255, .75);
  padding: 1px $md;
  border: 1px solid rgba(255, 255, 255, .1);
  cursor: default;
  background: rgba(255, 255, 255, .15);
  margin: 0 $md;
  border-radius: 2px;

  .remove {
    padding: 1px 0 0 $md;
  }

  &:hover {
    color: rgba(255, 255, 255, 1);

    .remove {
      color: red;
      cursor: pointer;
    }
  }

  +.tag {}
}


.rulesTable {
  color: rgba(255, 255, 255, .75);
  margin-top: 16px;


  tr:nth-child(2n) {
    td {
      background: rgba(255, 255, 255, .05)
    }
  }


  .ruleRowHeaders {
    th {
      border-bottom: 1px solid rgba(255, 255, 255, .25);
      text-align: start;
      padding-left: 10px;

      >div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: $md 0;
      }



      +th {}
    }
  }



  .ruleRow {
    position: relative;
    text-align: center;
    vertical-align: middle;
    color: goldenrod;
    font-size: $fnt-xs;
    font-weight: normal;
    border: 1px solid rgba(255, 255, 255, .2);
    padding: 0 $sm;
    margin: 0 12px;


    td {
      padding: 0 $sm;
      white-space: nowrap;
    }
  }

  .ruleRowAnd {
    position: relative;
    text-align: center;
    color: $blue; //goldenrod;
    font-size: $fnt-xs;
    font-weight: normal;
    //border:1px solid rgba(255,255,255,.2);
    padding: 0 $sm;
    text-transform: uppercase;

    display: inline-flex;
    //border:1px solid rgba(255,255,255,.2);
    //background: rgba(0,0,0,.1);
    //transform: rotate(-5deg);


  }

  .ruleRowOr {
    position: relative;
    text-align: left;
    padding: 0 $sm;
    margin: 0 12px;
    margin-left: 20px;
    color: $blue;
    font-size: $fnt-xs;
    font-weight: normal;
    text-transform: uppercase;
    display: inline-flex;
    border: 1px solid rgba(255, 255, 255, .2);
    background: rgba(0, 0, 0, .1);
    transform: rotate(-5deg);
    //border:1px solid rgba(255,255,255,.2);
  }

  .ruleNum {
    font-size: 16px;
  }

  .ruleRowRemove {
    text-align: center;
  }


  .addNewRule {
    td {
      background: transparent !important;
      padding: $md;
    }
  }

  code {
    background: rgba(0, 0, 0, .25);
    padding: 2px;
    white-space: nowrap;
  }


}


.clickToEdit_ReadMode {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font: $fnt-main-mono;
  font-size: $fnt-sm;
  cursor: text;
  top: 1px;
  //border:1px solid transparent;
  margin: $md 0 $md + 5;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(0, 0, 0, 0.2);
  padding: 0 5px;
  overflow: hidden;
  word-break: break-all;
  min-height: 50px !important;

  .placeholder {
    color: rgba(255, 255, 255, .5);
  }

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(0, 0, 0, 0.2);
  }
}

.clickToEdit_EditMode {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: rgba(0, 0, 0, 0.2);
  font: $fnt-main-mono;
  font-size: $fnt-sm;
  outline: none;
  margin: $md 0 $md - 1;
  border-radius: 2px;
  padding: 5px 5px;
  min-height: 55px !important;
}



.toolbar {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 0;
  padding: 12px 0;
  border-top: 1px solid rgba(255, 255, 255, .1);


  .btnInset {
    display: flex;
    align-items: center;
    margin-left: $lg;
    background: rgba(0, 0, 0, .4);
    border-radius: 3px;
    padding: $md;
    font-size: $fnt-md;
  }

  >* {
    margin-right: 24px;
  }
}


.MenuButton {
  position: relative;



  &:hover {
    .MenuButtonMenu {
      display: inline-flex;
      flex-direction: column;
      width: fit-content;
      max-height: 40vh;
      overflow-y: auto;
      @extend %custom-scrollbar;

    }
  }
}

.menuButtonMenuShield {
  position: absolute;
  display: flex;
  width: 100vh;
  height: 100vw;
  top: 0;
  left: 0;
  z-index: 5000 + $z-index-menu;

  .MenuButtonMenu {
    position: absolute;
    z-index: $z-index-menu + 1;
    background: $scilo-menu-bg;
    border: 1px solid $scilo-menu-border;
    color: $scilo-menu-txt;

    display: inline-flex;
    flex-direction: column;
    width: fit-content;
    max-height: 20vh;
    overflow-y: auto;
    @extend %custom-scrollbar;

    &.down {
      bottom: auto;
      top: 100%;
    }

    >div {
      padding: $md;
      cursor: pointer;

      //border-top:1px solid rgba(255,255,255,.1);
      &:hover {
        color: rgba(255, 255, 255, 1);
        background: $scilo-menu-hover;
      }
    }
  }

}


.SimpleDropdown {
  position: relative;
  display: block;
  width: 100%;

  .SimpleDropdownField {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border: 1px solid $field-border-color;
    background: $field-bg-color;
    color: $field-text-color;
    padding: $xs $md;
    border-radius: 3px;
    font-size: $fnt-md;
  }

  .SimpleDropdownSelectedValues {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .SimpleDropdownMenu {
    position: absolute;
    z-index: 10;
    display: none;
    left: 0;
    bottom: 100%;
    background: #20466B;
    border: 1px solid #7990A6;
    color: rgba(255, 255, 255, .7);


    &.down {
      bottom: auto;
      top: 100%;
    }

    .SimpleDropdownMenuItem,
    .SimpleDropdownMenuItemSelected {
      display: flex;
      justify-content: flex-start;
      padding: $xs $sm;
      font-size: $fnt-md;

      cursor: pointer;

      &:hover {
        color: rgba(255, 255, 255, 1);
        background: #2c6092;
      }


    }

    .SimpleDropdownMenuItemSelected {
      color: $blue;
      font-weight: bold;
    }


  }

  &:hover {
    .SimpleDropdownMenu {
      display: flex;
      flex-direction: column;
      justify-items: flex-start;
      max-height: 20vh;
      @extend %custom-scrollbar;
    }
  }
}

.errorBox {
  $bg: rgba(0, 0, 0, .25); //rgba(255,255,255,.2);
  position: relative;
  display: flex;
  background: $bg;
  justify-content: center;
  color: #ff9898;
  padding: $sm $md;
  font-size: $fnt-lg;
  width: 100%;
  flex-wrap: wrap;

  &:before {
    content: '';
    position: absolute;
    justify-self: center;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $bg transparent;
  }



  &.tagr_labels_regex {
    &:before {
      justify-self: flex-start;
      left: 10%;
    }
  }

  &.tagr_etext_regex {
    &:before {
      justify-self: flex-start;
      right: 10%;
    }
  }

}

//Theming
body:global(.darkTheme) {
  $txt-color: rgba(255, 255, 255, .75);
  $tag-border: rgba(255, 255, 255, .1);
  $tag-bg: (255, 255, 255, .15);
  $tag-hover: rgba(0, 0, 0, 1);
  $menu-border-color: rgba(0, 0, 0, 0.14); // rgb(240, 240, 240);
  $menu-bg: rgb(46, 46, 46);
  $menu-txt: rgba(255, 255, 255, 1);
  $menu-hover: rgb(37, 37, 37);

  .rulesTable {
    color: $txt-color;
  }

  .tag {
    color: $txt-color;
    border-color: $tag-border;
    background: $tag-bg;

    &:hover {
      color: $tag-hover;
    }
  }

  .clickToEdit_EditMode {
    color: rgb(255, 255, 0);
  }

  .MenuButtonMenu {
    background: $menu-bg;
    color: $menu-txt;
    border: 1px solid $menu-border-color;

    >div {
      &:hover {
        color:  $txt-color;
        background: $menu-hover;
      }
    }
  }
}

body:global(.lightTheme) {
  $txt-color: rgba(0, 0, 0, .75);
  $tag-border: rgba(0, 0, 0, .1);
  $tag-bg: rgba(0, 0, 0, .15);
  $tag-hover: rgba(0, 0, 0, 1);
  $menu-bg: #ffffff;
  $menu-txt: rgb(33, 33, 33);
  $menu-hover: rgb(245, 245, 245);
  $menu-border-color: rgb(240, 240, 240);

  .section {
    .sectionTitle {
      color: rgb(102, 102, 102);
    }
  }
  .tag {
    color: rgba(0, 0, 0, .75);
    border: 1px solid rgba(0, 0, 0, .1);
    background: rgba(0, 0, 0, .15);
    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
  .rulesTable {
    .ruleRowHeaders {
      th {
        color: rgb(153, 153, 153);
        border-bottom: 1px solid rgba(0, 0, 0, .25);
      }
    }
    .ruleRow {
      color: rgb(153, 153, 153);
      border: 1px solid rgba(0, 0, 0, .2);
    }
  }
  .clickToEdit_ReadMode {
    color: rgb(0, 0, 0);
    border-width: 0 0 2px 0;
    border-color: rgb(176, 176, 176);
    background: rgb(240, 240, 240);
    .placeholder {
      color: rgba(0, 0, 0, .5);
    }
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.3);
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .clickToEdit_EditMode {
    color: rgb(0, 0, 0);
    background: rgb(240, 240, 240);
    border-width: 0 0 2px 0;
    border-color: rgb(176, 176, 176);
  }
  .errorBox {
    color: rgb(204, 0, 0);
  }


  .MenuButtonMenu {
    background: $menu-bg;
    color: $menu-txt;
    border: 1px solid $menu-border-color;

    >div {
      &:hover {
        color:  $txt-color;
        background: $menu-hover;
      }
    }
  }


}