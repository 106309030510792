@import '~athena-next-ui-lib/dist/_global-vars.scss';


$yellow: #e8c819;
$black: #000;
$duration: .15s;
$bar-bg: #4E5154;

.filterSummaryText {
  margin-left: $lg;
}

.filterSummary {
  background: #262b30;
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 0 1;
  align-items: center;
  font: $fnt-main;
  font-size: $fnt-sm;
  padding: $xs 10px;
  color: rgba(255, 255, 255, .5);
  border-bottom: 1px solid rgba(255, 255, 255, .1);

  i {
    padding-right: 4px;
  }

  span {
    color: rgba(255, 255, 255, .5);
    font-weight: bold;
    padding: 0 4px;
  }

  b {
    margin: 0 $sm;
    color: #86cdff; //#b4d6ff; //rgba(255,255,255,.8);
    word-break: break-word;
  }

  s {
    text-decoration: none;
    color: rgba(255, 255, 255, .5);
    font-style: italic;
    font-weight: normal;
    margin-right: 3px;
  }

  u {
    text-decoration: none;
    font-weight: normal;
  }

  .forceRight {
    margin-left: auto;
  }

}


.promptToScrollButton {
  $bg: #cb73d0;
  position: absolute;
  z-index: 100;
  top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex: 0 1 auto;
  margin: auto;
  width: auto;
  height: auto;
  text-align: center;


  background: $bg;
  color: #fff;
  font-size: $fnt-sm;
  border-radius: 25px;
  padding: 2px 8px;
  border: 2px solid #fff;
  text-transform: uppercase;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, .5);
  transition: .25s all ease-out;
  flex-wrap: nowrap;
  white-space: nowrap;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
    background: darken($bg, 10%);
    //box-shadow: 0 0 5px 5px rgba(255,255,255,.1);
    box-shadow: 0 0 5px 5px rgba(72, 164, 231, 0.25);
  }

  &.hide {
    top: -100px;
    opacity: 0;
  }
}


.hidden {
  visibility: hidden;
}


.event {
  +.event {
    margin-top: $sm;
    padding-top: $sm;
    border-top: 1px solid rgba(255, 255, 255, .1);
  }
}



body:global(.darkTheme) {}

body:global(.lightTheme) {

  .filterSummary {
    color: rgb(0, 0, 0);
    background: rgb(192, 192, 192);
    border-bottom-color: rgb(153, 153, 153);
    margin-top:-1px;
  }

  .filterSummaryText {

    span {
      color: rgb(132, 132, 132);
    }

    b {
      color: rgb(23, 101, 190)
    }

    s {
      color: rgba(0, 0, 0, .5);
    }

  }


}